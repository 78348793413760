import React, { Component } from 'react'

class PSAPStatistic extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        if(!this.props.loaded){
            return <div className="statisticBlock">
                <h1>Calculating Compatible PSAPs...</h1>
                <hr id="statisticDivider"></hr>
            </div>
        } else {
            let compatiblePSAPS = this.props.map.get('Compatible PSAPs');
            let totalPSAPS = this.props.map.get("Total PSAPs");

            let percentage = Math.trunc((compatiblePSAPS / totalPSAPS) * 100);
            
            return <div className="statisticBlock">
                <h2><span id="statistic">{percentage}%</span> of 911 call centers in the U.S. 
                provide text to 911 services.</h2>
                <hr id="statisticDivider"></hr>
            </div>
        }
    }
}

export default PSAPStatistic;