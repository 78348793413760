import React, { Component } from 'react'
import { Nav, Navbar, Dropdown } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import MobileNav from './mobileNav.js'

class SiteNavbar extends Component {
    render() {
        return (
            <div className="outerContainer">
                <Navbar className="navStyle" expand="lg">
                    <Navbar.Brand className='brandStyle' target="_blank" 
                        href="https://accessos.io">
                        <img src="./accessos-icon.png" className="brandImage"/>
                            <span className="headerTitle">acces</span>
                            <span className="headerTitle redTitle">SOS</span>
                    </Navbar.Brand>
                    <div className='headerDesktop'>
                        <div id="basic-navbar-nav" className="desktopNav">
                            <Dropdown>
                                <Dropdown.Toggle className="linkStyle" id="dropdown-basic">
                                    Our Solution
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item className="linkStyle dropdownItemText" onClick={() => {
                                        // Trigger a smooth scroll to the 'steps' div - this way of doing it is required to
                                        // work on chrome browsers.
                                        location.href = "#";
                                        location.href = "#steps"; }
                                    }>
                                        How Text to 911 Works
                                    </Dropdown.Item>
                                    <Dropdown.Item className="linkStyle dropdownItemText" target="_blank" rel="noreferrer" href="https://accessos.io/how-it-works/">
                                        How accesSOS Works
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                                {/* <Nav.Link className="linkStyle" target="_blank" href="https://accessos.io/how-it-works/">
                                    Our Solution</Nav.Link> */}
                                <Nav.Link className="linkStyle" href="#FAQ">
                                    FAQ
                                </Nav.Link>
                                <Nav.Link className="linkStyle" target="_blank" rel="noreferrer" href="https://accessos.io/resources_page/">
                                    Resources
                                </Nav.Link>
                                <Nav.Link className="linkStyle donateBlock" target="_blank" rel="noreferrer" href="https://donorbox.org/911textapp-nationwide"> 
                                    Donate
                                </Nav.Link>
                            </div>
                    </div>
                    <div className='headerMobile'><MobileNav/></div>
                </Navbar>
                <div className="subscribeReminder">
                    accesSOS is a free, 24/7 app that connects you to 911 and mental health services without the need to speak.
                    <a style={{padding: '0 0.2em 0 0.2em', fontSize: '1em'}} target="_blank" rel="noreferrer" href="https://qr.accessos.io/appdownload"> 
                        <b><u>Download now</u></b> 
                    </a>
                    to be prepared. 
                </div>
            </div>
        );
    }
}

// render(<SiteNavbar/>, document.getElementById('navbar'));
export default SiteNavbar;